import './SubjectProfileQuickView.less'

import { Button } from 'antd'
import React, { useEffect } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { SubjectInRepository } from '../../../../requests'
import { TableRecord } from '../../../../utils'
import { DatacDrawer, DatacIcon } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../../SubjectRepositoryDashboard/SubjectRepositoryDashboardStore'
import { SubjectProfileCharacteristics } from '../SubjectProfileCharacteristics'
import { SubjectProfileContactInfo } from '../SubjectProfileContactInfo'
import { SubjectProfileHeader } from '../SubjectProfileHeader'
import { useSubjectRepositoryProfileStore } from '../SubjectRepositoryProfileStore'
import { SubjectStudiesList } from '../SubjectStudiesList'

interface SubjectProfileQuickViewProps {
  onClose: () => void
}

export const SubjectProfileQuickView: React.VFC<SubjectProfileQuickViewProps> = ({ onClose }) => {
  const intlProfile = useScopedIntl('subject_repository.profile')
  const { currentSubject, setCurrentSubject } = useSubjectRepositoryProfileStore()
  const { subjects, centersOptions, subjectToView, setSubjectToView } = useSubjectRepositoryDashboardStore()

  useEffect(() => {
    setCurrentSubject(subjectToView)
  }, [subjectToView])

  const getRelativeSubjectToPreview = (shift: number) => {
    if (!subjectToView) return null
    const index = subjects.findIndex((s: TableRecord<SubjectInRepository>) => s.id === subjectToView.id)
    if (index + shift < 0) return null
    return subjects.slice(index + shift)[0]
  }

  return (
    <DatacDrawer
      isOpened={!!subjectToView}
      onClose={() => onClose()}
      destroyOnClose
      bodyClass="subject-profile-quick-view"
    >
      {!!currentSubject && (
        <>
          <SubjectProfileHeader onDelete={onClose} />

          <div className="subject-profile-quick-view__body">
            <SubjectProfileContactInfo centersOptions={centersOptions} />

            <SubjectProfileCharacteristics subjectId={currentSubject.id} />

            <SubjectStudiesList subjectId={currentSubject.id} isInQuickView />
          </div>

          <div className="subject-profile-quick-view__footer">
            <div className="subject-profile-quick-view__footer__previous">
              {!!getRelativeSubjectToPreview(-1)?.id && (
                <Button
                  className="datac-button"
                  size="large"
                  type="default"
                  onClick={() => setSubjectToView(getRelativeSubjectToPreview(-1))}
                >
                  <DatacIcon name="chevronLeft" size="xbig" type="blue" />
                  <span>{intlProfile('previous')}</span>
                </Button>
              )}
            </div>
            <div className="subject-profile-quick-view__footer__next">
              {!!getRelativeSubjectToPreview(1)?.id && (
                <Button
                  className="datac-button"
                  size="large"
                  type="default"
                  onClick={() => setSubjectToView(getRelativeSubjectToPreview(1))}
                >
                  <span>{intlProfile('next')}</span>
                  <DatacIcon name="chevronRight" size="xbig" type="blue" />
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </DatacDrawer>
  )
}
