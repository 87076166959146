import { Form } from 'antd'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { createNewSubjectInRepository } from '../../../../requests'
import { UserContext } from '../../../auth'
import { DatacMessage, DatacModal, DatacOption } from '../../../common'
import {
  NewSubjectFields,
  SubjectFields,
  SubjectForm,
  SubjectFormData,
  SubjectIdServerError
} from '../../../shared/SubjectForm'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

interface AddSubjectModalProps {
  onClose: (newSubjectId?: string) => void
}

export const AddSubjectModal: React.FC<AddSubjectModalProps> = ({ onClose }) => {
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [subjectIdServerError, setSubjectIdServerError] = useState<SubjectIdServerError>(null)
  const [isEmailAlreadyUsed, setIsEmailAlreadyUsed] = useState(false)
  const { centersOptions, isNewSubjectModalOpened } = useSubjectRepositoryDashboardStore()
  const { user } = useContext(UserContext)

  const onModalClose = (newSubjectId?: string) => {
    formInstance.resetFields()
    setIsEmailAlreadyUsed(null)
    setSubjectIdServerError(null)
    onClose(newSubjectId)
  }

  const onModalSubmit = ({
    id: subjectId,
    firstName,
    lastName,
    email,
    internationalPhoneNumber,
    centerId: mainCenterId,
    language
  }: SubjectFormData) => {
    createNewSubjectInRepository(
      { subjectId, firstName, lastName, email, internationalPhoneNumber, mainCenterId, language },
      {
        onSuccess: () => onModalClose(subjectId),
        onRequestError: code => DatacMessage.genericError(intl, code),
        onSubjectIdAlreadyUsed: () => setSubjectIdServerError(SubjectIdServerError.SubjectIdAlreadyUsed),
        onEmailAlreadyUsed: () => setIsEmailAlreadyUsed(true)
      }
    )
  }

  return (
    <Form form={formInstance} onFinish={(data: SubjectFormData) => onModalSubmit(data as SubjectFormData)}>
      <DatacModal
        title={intl('subject_repository.add_subject')}
        isOpened={isNewSubjectModalOpened}
        onClose={() => onModalClose()}
        onSubmit={() => formInstance.submit()}
        destroyOnClose
      >
        <SubjectForm onClose={onModalClose}>
          <SubjectFields
            isEmailAlreadyUsed={isEmailAlreadyUsed}
            setIsEmailAlreadyUsed={setIsEmailAlreadyUsed}
            formInstance={formInstance}
          />
          <NewSubjectFields
            subjectIdServerError={subjectIdServerError}
            formInstance={formInstance}
            centers={centersOptions.filter(
              (option: DatacOption) => user.centerIds.includes(option.value) || (user.isAdmin && !user.centerIds.length)
            )}
            setSubjectIdServerError={setSubjectIdServerError}
          />
        </SubjectForm>
      </DatacModal>
    </Form>
  )
}
