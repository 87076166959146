import './RestartModal.less'

import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { resendQuestionnaires } from '../../../../requests'
import { AdvancedFiltersToConditions, DatacInformationMessage, DatacMessage, DatacModal } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

interface Props {
  subjectsToRestartCount: number
}

export const RestartModal: React.VFC<Props> = ({ subjectsToRestartCount }) => {
  const intlModal = useScopedIntl('subject_repository.resend.modal')
  const intl = useScopedIntl('')

  const {
    selectedSubjects,
    search,
    filters,
    isRestartModalOpened,
    setIsRestartModalOpened,
    isEverythingSelected,
    getParsedStatusFilter
  } = useSubjectRepositoryDashboardStore()

  const onSendMessage = () => {
    resendQuestionnaires(
      {
        search,
        conditions: AdvancedFiltersToConditions(filters),
        status: getParsedStatusFilter(),
        subjects: isEverythingSelected ? undefined : selectedSubjects
      },
      {
        onSuccess: () => setIsRestartModalOpened(false),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isRestartModalOpened}
      onClose={() => setIsRestartModalOpened(false)}
      onSubmit={() => onSendMessage()}
      destroyOnClose
    >
      <div className="subject-repository-resend-modal__body">
        <DatacInformationMessage
          message={
            <span>
              {subjectsToRestartCount === 1
                ? intlModal('subject_count_info_one')
                : intlModal('subject_count_info', { count: subjectsToRestartCount })}
              <br />
              {intlModal('description')}
            </span>
          }
        />
      </div>
    </DatacModal>
  )
}
